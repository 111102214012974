import { memo, useCallback } from 'react';
import { cn, GalleryWithProgress } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import Post from './elems/Post';
import PromoPlaceholder from './elems/PromoPlaceholder';
import styles from './InstagramGallery.module.css';

import type { InstagramPostData, InstagramAccountData } from '@Types/InstagramPost';
import type { FC, HTMLAttributes } from 'react';
import type { ProgressOptions } from '@divlab/divanui';

export interface InstagramGalleryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  slideIndex?: number;
  posts: InstagramPostData[];
  hasPromoPlaceholder?: boolean;
  account?: InstagramAccountData;
  onChangeProgress?(opts: ProgressOptions): void;
  onChangeCurrent?(state: any): void;
}

const InstagramGallery: FC<InstagramGalleryProps> = (props) => {
  const {
    className,
    slideIndex,
    posts,
    account,
    hasPromoPlaceholder,
    onChangeProgress,
    onChangeCurrent,
    ...restProps
  } = props;

  const handleClickInstagramPost = useCallback((_e, selectedPost) => {
    if (window.cancelClick) return;

    ModalWindows.open('InstagramPost', {
      selectedId: selectedPost.id,
    });
  }, []);

  return (
    <div {...restProps} className={cn(styles.instagram, className)}>
      <GalleryWithProgress
        cnGallery={styles.gallery}
        cnTrack={styles.track}
        slideIndex={slideIndex}
        key={posts.length}
        onChangeProgress={onChangeProgress}
        onChangeCurrent={onChangeCurrent}
      >
        {hasPromoPlaceholder && (
          <div className={styles.item}>
            <PromoPlaceholder account={account} />
          </div>
        )}
        {posts.map((post) => (
          <div className={styles.item} key={post.id}>
            <Post
              className={styles.post}
              post={post}
              onClick={(e) => handleClickInstagramPost(e, post)}
            />
          </div>
        ))}
      </GalleryWithProgress>
    </div>
  );
};

export default memo(InstagramGallery);
