import * as ModalWindows from '@Stores/ModalWindows';
import * as Meta from '@Queries/Meta';
import { useRequest } from '@Contexts/Request';
import useSEOCategories from '@Queries/useSEOCategories';
import usePage from '@Queries/usePage';
import useTranslation from '@Queries/useTranslation';
import usePromotions from '@Queries/usePromotions';
import usePromoPopup from '@Promo/hooks/usePromoPopup';
import LayoutMain from '@Layouts/LayoutMain';
import Page from '@Components/Page';
import Suspense from '@Components/Suspense';
import MainInstagramSection from '@Components/MainInstagramSection';
import Popular from '@Components/Popular';
import ShowroomsRenderByRegion from '@Components/ShowroomsMapNew/ShowroomsRenderByRegion';
import Recommendations from './elems/Recommendations';
import MainGallery from './elems/MainGallery';
import Hits from './elems/Hits';
import ExtraSale from './elems/ExtraSale';
import NewProducts from './elems/NewProducts';
import Ideas from './elems/Ideas';
import Categories from './elems/Categories';
import FindYourOwn from './elems/FindYourOwn';
import CategoriesInStockBlock from './elems/CategoriesInStockBlock';
import MainDescription from './elems/MainDescription';
import styles from './PageIndex.module.css';

import type { FC } from 'react';
import type { PageIndexData } from './typings';

const PageIndex: FC = () => {
  const page = usePage<PageIndexData>();
  const {
    popularCategories,
    categoriesInStock,
    extraDiscounts,
    bestsellers,
    novelties,
    text,
    homeIdeas,
    categoryPopularTop,
    interiorBlock,
  } = page.data;
  const { t } = useTranslation();
  const { data: meta } = Meta.useMeta();
  const isMoscow = meta.region.id === 1;
  const regions = Meta.useAllRegions({ enabled: isMoscow });
  const seoCategories = useSEOCategories({ enabled: isMoscow });
  const mainPromotions = usePromotions({ listName: 'main', type: 'main' });
  const cardPromotions = usePromotions({ listName: 'recommendations', type: 'card' });
  const popup = usePromoPopup({ slug: '' });
  const { isBlr } = useRequest();

  ModalWindows.useSSRModals(
    [
      {
        id: 'AllRegions',
        data: {
          regions: regions.data,
        },
      },
    ],
    !!regions.data,
  );

  ModalWindows.useSSRModals(
    [
      {
        id: 'SEOSofasCategory',
        data: {
          seoCategories: seoCategories.data?.sofas,
        },
      },
      {
        id: 'SEOClosetsCategory',
        data: {
          seoCategories: seoCategories.data?.closets,
        },
      },
      {
        id: 'SEOBedsCategory',
        data: {
          seoCategories: seoCategories.data?.beds,
        },
      },
    ],
    !!seoCategories.data,
  );

  return (
    <LayoutMain>
      <Page>
        <div className={styles.pageIndex} data-testid='page-index'>
          <div className={styles.banner}>
            <div className={styles.galleryPlaceholder}>
              {mainPromotions.data && <MainGallery promotions={mainPromotions.data} />}
            </div>
          </div>

          <Suspense fallback={null}>{popup}</Suspense>

          {categoryPopularTop?.length > 0 && (
            <div className={styles.section}>
              <Categories categories={categoryPopularTop} />
            </div>
          )}

          {extraDiscounts?.items?.length > 0 && (
            <div className={styles.section}>
              <ExtraSale section={extraDiscounts} />
            </div>
          )}

          {!extraDiscounts && bestsellers?.items?.length > 0 && (
            <div className={styles.section}>
              <Hits section={bestsellers} data-testid='hits' />
            </div>
          )}

          {cardPromotions.data?.length > 0 && (
            <div className={styles.section}>
              <Recommendations promotions={cardPromotions.data} />
            </div>
          )}

          {popularCategories?.length > 0 && (
            <div className={styles.section}>
              <Popular title={t('popular-categories')} categories={popularCategories} />
            </div>
          )}

          {categoriesInStock && (
            <div className={styles.section}>
              <CategoriesInStockBlock data={categoriesInStock} />
            </div>
          )}

          {interiorBlock && (
            <div className={styles.section}>
              <FindYourOwn data={interiorBlock} />
            </div>
          )}

          {extraDiscounts && bestsellers?.items?.length > 0 && (
            <div className={styles.section}>
              <Hits section={bestsellers} data-testid='hits' />
            </div>
          )}

          {homeIdeas?.length > 0 && (
            <div className={styles.section}>
              <Ideas title={t('home-ideas')} ideas={homeIdeas} />
            </div>
          )}

          {novelties?.items?.length > 0 && (
            <div className={styles.section}>
              <NewProducts section={novelties} />
            </div>
          )}

          {page.data.map && (
            <div className={styles.container}>
              <div className={styles.section}>
                <ShowroomsRenderByRegion className={styles.map} map={page.data.map} />
              </div>
            </div>
          )}

          {page.data.instagram?.items?.length > 0 && (
            <div className={styles.section}>
              <MainInstagramSection
                title={page.data.instagram.headerText}
                className={styles.instagram}
                posts={page.data.instagram.items}
                typeSection={isBlr ? 'universal' : 'secondary'}
              />
            </div>
          )}

          {!!text?.text && (
            <MainDescription
              text={text}
              regions={regions.data}
              seoCategories={seoCategories.data}
              className={styles.descriptionWrapper}
            />
          )}
        </div>
      </Page>
    </LayoutMain>
  );
};

export default PageIndex;
