import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import useTranslation from '@Queries/useTranslation';
import styles from './PromoPlaceholder.module.css';

import type { InstagramAccountData } from '@Types/InstagramPost';
import type { FC, HTMLAttributes } from 'react';

export interface PromoPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  account: InstagramAccountData;
}

const PromoPlaceholder: FC<PromoPlaceholderProps> = (props) => {
  const { className, account, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <div {...restProps} className={cn(styles.promoPlaceholder, className)}>
      <div className={styles.content}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: t('ui.instagram.promo.want-your-photo') }}
        />
        <div className={styles.text}>
          {t('ui.instagram.promo.tag-us.before-link')}
          <Link view='native' to={account.link}>
            {account.name}
          </Link>
          <br />
          {t('ui.instagram.promo.tag-us.after-link')}
        </div>
      </div>
    </div>
  );
};

export default memo(PromoPlaceholder);
