import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import Link from '@Navigation/Link';
import useTranslation from '@Queries/useTranslation';
import styles from './PopularTrendsCard.module.css';

import type { PopularCategoryData } from '@Components/Popular/typings';
import type { LinkProps } from '@Navigation/Link';
import type { FC } from 'react';

export interface PopularTrendsCardProps extends Omit<LinkProps, 'to'> {
  className?: string;
  category: PopularCategoryData;
}

const PopularTrendsCard: FC<PopularTrendsCardProps> = (props) => {
  const { className, category, ...restProps } = props;
  const { t } = useTranslation();
  const models = `${category.count} ${t('ui.plural-models', category.count)}`;

  return (
    <Link {...restProps} to={category.url} className={cn(styles.wrapper, className)}>
      <div className={styles.imgWrapper}>
        <Img className={styles.image} cnImage={styles.cnImage} src={category.image} />
      </div>
      <div className={styles.titleWrap}>
        <span className={styles.title}>
          {category.type} {category.name}
        </span>
        {category.count && (
          <span className={styles.count}>
            {t('ui.more-products.begin')} {models} {t('ui.more-products.end')}
          </span>
        )}
      </div>
    </Link>
  );
};

export default PopularTrendsCard;
