import { memo } from 'react';

import ShowroomsMap from '@Components/ShowroomsMap';
import SmartRender from '@Components/SmartRender';
import ShowroomsMapNew from './ShowroomsMapNew';

import type { MapData } from '@Types/Map';
import type { FC } from 'react';

interface ShowroomsRenderByRegionProps {
  map?: MapData;
  className?: string;
}

const ShowroomsRenderByRegion: FC<ShowroomsRenderByRegionProps> = (props) => {
  const { map, className } = props;
  return (
    <>
      <SmartRender countries={['UZB']}>
        <ShowroomsMap map={map} className={className} />
      </SmartRender>
      <SmartRender countries={['RUS', 'BLR', 'KAZ']}>
        <ShowroomsMapNew map={map} className={className} />
      </SmartRender>
    </>
  );
};

export default memo(ShowroomsRenderByRegion);
