import { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { IconChevronBottom } from '@divlab/divanui/icons';
import { cn, RawHtml } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import * as ModalWindows from '@Stores/ModalWindows';
import styles from './MainDescription.module.css';

import type { FC, MouseEvent } from 'react';
import type { RegionHintData } from '@Types/Region';
import type { SEOCategories } from '@Types/Category';
import type { TextData } from '../../typings';

interface MainDescriptionProps {
  className?: string;
  regions?: RegionHintData[];
  seoCategories?: SEOCategories;
  text: TextData;
}

const MainDescription: FC<MainDescriptionProps> = (props) => {
  const { className, regions, seoCategories, text } = props;
  const [loaded, setLoaded] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const [firstParagraph] = text.text;
  const lastParagraph = text.text[text.text.length - 1];
  const areManyParagraphs = text.text.length > 1;
  const isReadMoreButtonVisible = areManyParagraphs && collapsed;

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const target = e.target as HTMLElement;
      const categories = target.dataset.categories;

      if (target.dataset.regions) {
        if (regions) {
          ModalWindows.open('AllRegions', {
            regions,
          });
        } else {
          ModalWindows.open('Info', { view: 'error', variant: 'unknown-error' });
        }
      }

      if (['sofas', 'closets', 'beds'].includes(categories)) {
        if (seoCategories) {
          const category = categories.charAt(0).toUpperCase() + categories.slice(1);

          ModalWindows.open(`SEO${category}Category`, {
            seoCategories: seoCategories[categories],
          });
        } else {
          ModalWindows.open('Info', { view: 'error', variant: 'unknown-error' });
        }
      }
    },
    [regions, seoCategories],
  );

  const handleButton = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  /*По требовнию SEO кнопка "Читать далее" должна быть реализована исключительно на js.
  Никаких упоминаний кнопки в HTML быть не должно */

  const buttonReadMore = useMemo(() => {
    if (!loaded) return null;

    return (
      <div className={styles.button} onClick={handleButton}>
        {' '}
        {collapsed ? t('ui.read-more') : t('ui.hide')}
        <IconChevronBottom className={cn(styles.chevron, { [styles.active]: !collapsed })} />
      </div>
    );
  }, [t, handleButton, collapsed, loaded]);

  return (
    <div className={className}>
      {!!text.title && <div className={styles.title}>{text.title}</div>}
      <div className={styles.block}>
        <RawHtml
          className={cn(areManyParagraphs && styles.inlineParagraph)}
          key={firstParagraph}
          html={firstParagraph}
          onClick={handleClick}
        />
        {isReadMoreButtonVisible && buttonReadMore}
      </div>

      {areManyParagraphs && (
        <div className={cn(styles.block, { [styles.hidden]: loaded && collapsed })}>
          {text.text.slice(1, text.text.length - 1).map((item) => {
            return <RawHtml key={item} html={item} onClick={handleClick} />;
          })}

          <RawHtml
            className={styles.inlineParagraph}
            key={lastParagraph}
            html={lastParagraph}
            onClick={handleClick}
          />
          {buttonReadMore}
        </div>
      )}
    </div>
  );
};

export default memo(MainDescription);
