import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './Post.module.css';

import type { InstagramPostData } from '@Types/InstagramPost';
import type { FC, HTMLAttributes } from 'react';

export interface PostProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  post: InstagramPostData;
}

const Post: FC<PostProps> = (props) => {
  const { className, post, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.post, className)}>
      <Img className={styles.image} src={post.img} alt={post.alt} />
      {post.author && <div className={styles.author}>{`@${post.author}`}</div>}
    </div>
  );
};

export default memo(Post);
