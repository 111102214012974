import { memo } from 'react';
import { cn } from '@divlab/divanui';

import CrossSaleProductCard from '@Components/CrossSaleProductCard';
import CrossSaleSection from '@Components/CrossSaleSection';
import styles from './ExtraSale.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProductsSectionData } from '@Pages/PageIndex/typings';

export interface ExtraSaleProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  section: ProductsSectionData;
}

const ExtraSale: FC<ExtraSaleProps> = (props) => {
  const { className, section, ...restProps } = props;

  return (
    <div {...restProps} className={cn(className, styles.wrapper)}>
      <CrossSaleSection
        cnGallery={styles.gallery}
        analyticsTitle={section.header}
        title={section.header}
        text={section.text}
        products={section.items}
        renderItem={(productCardProps) => (
          <div className={styles.item}>
            <CrossSaleProductCard {...productCardProps} isExtraSale />
          </div>
        )}
      />
    </div>
  );
};

export default memo(ExtraSale);
