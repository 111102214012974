import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import Link from '@Navigation/Link';
import styles from './CategoryCard.module.css';

import type { LinkProps } from '@Navigation/Link';
import type { CategoryData } from '@Pages/PageIndex';
import type { FC } from 'react';

export interface CategoryCardProps extends Omit<LinkProps, 'to'> {
  className?: string;
  category: CategoryData;
}

const CategoryCard: FC<CategoryCardProps> = (props) => {
  const { className, category, ...restProps } = props;
  const { url, backgroundColor } = category;

  return (
    <Link
      {...restProps}
      className={cn(styles.card, className)}
      to={url}
      style={{ backgroundColor: backgroundColor }}
    >
      <Img className={styles.image} src={category.image} />

      <div className={styles.title}>{category.name}</div>
    </Link>
  );
};

export default memo(CategoryCard);
