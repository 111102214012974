import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import image from './image.jpg';
import styles from './ExpressDesignBanner.module.css';

import type { FC } from 'react';

interface ExpressDesignBannerProps {
  className?: string;
}

const ExpressDesignBanner: FC<ExpressDesignBannerProps> = (props) => {
  const { className, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <div {...restProps} className={cn(styles.banner, className)}>
      <Img className={styles.image} cnImage={styles.cnImage} src={image} />
      <div className={styles.content}>
        <div className={styles.title}>{t('ui.express-design-banner.title')}</div>
        <div className={styles.text}>{t('ui.express-design-banner.text')}</div>
        <Link className={styles.link} to='/express-design' view='primary' underlined>
          {t('ui.more')}
        </Link>
      </div>
    </div>
  );
};

export default memo(ExpressDesignBanner);
