import { useMemo } from 'react';
import { cn, GalleryWithDots, NavArrows } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import useMedias from '@Hooks/useMedias';
import Section from '@Components/Section';
import PopularSlider from './elements/PopularSlider';
import styles from './Popular.module.css';

import type { PopularCategoryData } from './typings';
import type { FC, HTMLAttributes } from 'react';

export interface PopularProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  categories: PopularCategoryData[];
  title?: string;
  view?: 'trends';
}

const Popular: FC<PopularProps> = (props) => {
  const { className, title, categories, view, ...restProps } = props;
  const { isDesktop, isDesktopM, isMobile } = useMedias();
  const { isBlr } = useRequest();

  // В зависимости от ширины экрана получаем количество отображаемых слайдов
  const countCardsInSlide = useMemo(() => {
    if (isMobile) return 1;
    if (isDesktopM && view === 'trends') return 2;
    if (isDesktop) return 2;

    return 6;
  }, [isMobile, isDesktopM, isDesktop, view]);

  // Разбиваем категории на группы, 1 группа = 1 слайд
  const groups = useMemo(() => {
    const result: PopularCategoryData[][] = [];

    (categories || []).forEach((category, indexCategory) => {
      const index = Math.floor(indexCategory / countCardsInSlide);

      if (!result[index]) result[index] = [];
      result[index].push(category);
    });

    return result;
  }, [categories, countCardsInSlide]);

  return (
    <div {...restProps} className={cn(className, styles.wrapper)}>
      <div className={cn({ [styles.container]: view !== 'trends' })}>
        <div className={styles.galleryWrapper}>
          <GalleryWithDots
            className={styles.gallery}
            cnGallery={styles.galleryContainer}
            renderButtons={({ onNext, onPrev }) => (
              <Section
                className={styles.headerWrapper}
                title={title}
                arrows={<NavArrows onPrev={onPrev} onNext={onNext} />}
                typeSection={isBlr ? 'universal' : 'secondary'}
              />
            )}
          >
            {groups.map((groupCategories, index) => (
              <div className={styles.itemWrapper} key={index}>
                <PopularSlider count={countCardsInSlide} categories={groupCategories} view={view} />
              </div>
            ))}
          </GalleryWithDots>
        </div>
      </div>
    </div>
  );
};

export default Popular;
