import CardInView from '@Components/CardInView';
import HorizontalProductCard from '../HorizontalProductCard';
import styles from './VerticalProductsList.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

interface VerticalProductsProps extends HTMLAttributes<HTMLDivElement> {
  products: ProductData[];
  analyticsTitle?: string;
}

const VerticalProductsList: FC<VerticalProductsProps> = (props) => {
  const { products, analyticsTitle } = props;

  return (
    <div className={styles.productsContainer}>
      <div className={styles.productsList}>
        {products.map((product, index) => {
          return (
            <CardInView
              listTitle={analyticsTitle}
              card={product}
              cardType='product'
              id={product.id}
              position={index}
              key={product.id}
            >
              <HorizontalProductCard key={product.id} product={product} />
            </CardInView>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalProductsList;
