import PromoCardTemplate from './elems/PromoCardTempate';

import type { FC, HTMLAttributes } from 'react';
import type { CardBannerData } from '@Promo/typings';

interface PromoCardProps extends HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  card: CardBannerData;
}

const PromoCard: FC<PromoCardProps> = (props) => {
  const { className, card, ...restProps } = props;
  const template = card.template || 'default';

  switch (template) {
    case 'default': {
      return <PromoCardTemplate className={className} card={card} {...restProps} />;
    }
    default: {
      return null;
    }
  }
};

export default PromoCard;
