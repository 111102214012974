import { useCallback } from 'react';
import { useMedias, cn, Discount } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import Link from '@Navigation/Link';
import Img from '@UI/Img';
import Button from '@UI/Button';
import Text from '@Promo/elems/Text';
import Logo from '@Promo/elems/Logo';
import styles from './PromoCardTemplate.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CardBannerData } from '@Promo/typings';
import type { ModalId } from '@Stores/ModalWindows/typings';

const availableModals: ModalId[] = [
  'OrderTextileSamples',
  'VideoConsultation',
  'FurnitureDisposal',
];

interface PromoCardTemplateProps extends HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  card: CardBannerData;
}

const PromoCard: FC<PromoCardTemplateProps> = (props) => {
  const { className, card, ...restProps } = props;
  const { isMobile } = useMedias();

  const resources = card.resources;
  const logo = resources.default.logo;
  const text = card.text || {};
  const button = card.button;
  const sticker = card.sticker;

  const image = isMobile
    ? resources.default.mobileImage || resources.default.desktopImage
    : resources.default.desktopImage;

  const handleClick = useCallback(() => {
    if (availableModals.includes(card.modal)) {
      ModalWindows.open(card.modal);
    }
  }, [card.modal]);

  return (
    <Link
      className={cn(styles.card, className)}
      to={card.link}
      onClick={handleClick}
      {...restProps}
    >
      <div className={styles.container}>
        {image && <Img src={image} className={styles.image} />}
        <div className={cn(styles.text, { [styles.inversed]: card.inversed })}>
          <div className={styles.cardTop}>
            {text.period && (
              <Text
                className={styles.period}
                desktop={text.period}
                mobile={text.periodMobile}
                isMobile={isMobile}
              />
            )}
            {logo && <Logo className={styles.period} src={logo} size='S' />}

            {text.sticker && (
              <Discount
                className={styles.discount}
                value={text.sticker}
                simple={sticker?.type !== 'sale'}
                view='white'
                customText
              />
            )}
          </div>
          <div className={styles.cardBottom}>
            {text.title && (
              <Text
                className={styles.title}
                desktop={text.title}
                mobile={text.titleMobile}
                isMobile={isMobile}
              />
            )}
            {text.subtitle && (
              <Text
                className={styles.subtitle}
                desktop={text.subtitle}
                mobile={text.subtitleMobile}
                isMobile={isMobile}
              />
            )}
            {text.button && <Button theme={button?.theme || 'transparent'}>{text.button}</Button>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PromoCard;
