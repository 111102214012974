import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Sound.module.css';

import type { FC, HTMLAttributes } from 'react';

interface SoundProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  muted?: boolean;
}

const Sound: FC<SoundProps> = (props) => {
  const { className, muted, ...restProps } = props;

  return (
    <div className={cn(styles.sound, className)} {...restProps}>
      {muted ? (
        <svg
          className={styles.image}
          viewBox='0 0 33 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.2807 3.14915C21.2807 1.88591 19.8 1.0497 18.6116 1.75474L8.98863 7.46383V16.3168L18.5865 22.23C19.7736 22.9614 21.2807 22.1263 21.2807 20.8508V3.14915ZM7.45535 15.9808V7.79153H3.35427C2.33104 7.79153 1.53328 8.57916 1.53328 9.51291V14.2595C1.53328 15.1932 2.33104 15.9808 3.35427 15.9808H7.45535ZM8.00771 6.29137L17.8166 0.471975C19.9712 -0.806299 22.814 0.653628 22.814 3.14915V20.8508C22.814 23.3708 19.9218 24.8255 17.7695 23.4995L8.00073 17.481H3.35427C1.51928 17.481 0 16.0556 0 14.2595V9.51291C0 7.71676 1.51928 6.29137 3.35427 6.29137H8.00771ZM25.0986 8.24479C25.398 7.95187 25.8834 7.95187 26.1828 8.24479L28.937 10.9395L31.6913 8.24479C31.9907 7.95187 32.4761 7.95187 32.7755 8.24479C33.0748 8.53772 33.0748 9.01265 32.7755 9.30557L30.0212 12.0003L32.7755 14.6951C33.0748 14.988 33.0748 15.4629 32.7755 15.7559C32.4761 16.0488 31.9907 16.0488 31.6913 15.7559L28.937 13.0611L26.1828 15.7559C25.8834 16.0488 25.398 16.0488 25.0986 15.7559C24.7992 15.4629 24.7992 14.988 25.0986 14.6951L27.8528 12.0003L25.0986 9.30557C24.7992 9.01265 24.7992 8.53772 25.0986 8.24479Z'
            fill='currentColor'
          />
        </svg>
      ) : (
        <svg
          className={styles.image}
          viewBox='0 0 33 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.3085 3.14915C21.3085 1.88591 19.8489 1.0497 18.6774 1.75474L8.97091 7.46383V16.3168L18.6526 22.23C19.8228 22.9614 21.3085 22.1263 21.3085 20.8508V3.14915ZM7.45946 15.9808V7.79154L3.30651 7.79116C2.29785 7.79116 1.51145 8.57878 1.51145 9.51253V14.2591C1.51145 15.1928 2.29785 15.9804 3.30651 15.9804L7.45946 15.9808ZM8.00396 6.29137L17.8937 0.471975C20.0177 -0.8063 22.8199 0.653629 22.8199 3.14915V20.8508C22.8199 23.3708 19.9689 24.8255 17.8472 23.4995L7.99707 17.481L3.30651 17.4806C1.49765 17.4806 0 16.0552 0 14.2591V9.51253C0 7.71638 1.49764 6.29099 3.30651 6.29099L8.00396 6.29137ZM27.9075 2.86331C28.1902 2.5585 28.6682 2.53884 28.9753 2.81939C34.2396 7.62855 34.4378 16.0137 28.9895 21.2544C28.6898 21.5427 28.2113 21.5353 27.9208 21.2378C27.6304 20.9403 27.6379 20.4655 27.9376 20.1772C32.7725 15.5265 32.5663 8.13879 27.9517 3.92318C27.6446 3.64263 27.6248 3.16811 27.9075 2.86331ZM24.7109 6.75137C24.9936 6.44657 25.4717 6.4269 25.7788 6.70745C28.8285 9.49356 28.9361 14.343 25.7929 17.3664C25.4932 17.6547 25.0148 17.6472 24.7243 17.3497C24.4338 17.0522 24.4413 16.5774 24.741 16.2891C27.2708 13.8557 27.1552 10.0038 24.7552 7.81124C24.4481 7.53069 24.4283 7.05617 24.7109 6.75137Z'
            fill='currentColor'
          />
        </svg>
      )}
    </div>
  );
};

export default memo(Sound);
