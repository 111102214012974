import { memo } from 'react';
import { cn } from '@divlab/divanui';

import PopularCard from '../PopularCard';
import PopularTrendsCard from '../PopularTrendsCard';
import styles from './PopularSlider.module.css';

import type { PopularCategoryData } from '@Components/Popular/typings';
import type { FC, HTMLAttributes } from 'react';

export interface PopularSliderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  categories: PopularCategoryData[];
  count: 1 | 2 | 6;
  view?: 'trends';
}

const PopularSlider: FC<PopularSliderProps> = ({ categories, count, view }) => {
  const grids = {
    1: (
      <>
        {categories.map((category) =>
          view === 'trends' ? (
            <PopularTrendsCard key={category.id} category={category} />
          ) : (
            <PopularCard key={category.id} category={category} />
          ),
        )}
      </>
    ),
    2: (
      <div className={cn(styles.gridDesktop, { [styles.gridDesktopTrends]: view === 'trends' })}>
        {categories.map((category) => (
          <div key={category.id}>
            {view === 'trends' ? (
              <PopularTrendsCard category={category} />
            ) : (
              <PopularCard category={category} />
            )}
          </div>
        ))}
      </div>
    ),
    6: (
      <div className={cn(styles.gridDesktopM, { [styles.gridDesktopMTrends]: view === 'trends' })}>
        {categories.map((category, index) => (
          <div className={styles.cardDesktopM} key={category.id}>
            {view === 'trends' ? (
              <PopularTrendsCard category={category} />
            ) : (
              <PopularCard
                category={category}
                cardFit={!(index % 6) || !(index % 5) ? 'big' : 'small'}
              />
            )}
          </div>
        ))}
      </div>
    ),
  };

  return grids[count];
};

export default memo(PopularSlider);
