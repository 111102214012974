import { useQuery } from '@tanstack/react-query';

import ApiSite from '@Api/Site';

export interface Params {
  enabled?: boolean;
}

function useSEOCategories(params?: Params) {
  const { enabled } = params || {};
  const keys = ['seo-categories'];
  const result = useQuery({ queryKey: keys, queryFn: () => ApiSite.getSEOCategories(), enabled });

  return result;
}

export default useSEOCategories;
