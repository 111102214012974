import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonTabs, useMedias } from '@divlab/divanui';

import Img from '@UI/Img';
import useTranslation from '@Queries/useTranslation';
import ProductsInInterior from './elements/ProductsInInterior';
import styles from './FindYourOwn.module.css';

import type { FC } from 'react';
import type { Tab } from '@divlab/divanui';
import type { InteriorBlockData } from '@Pages/PageIndex';
import type { SectionProps } from '@Components/Section';

interface FindYourOwnProps extends SectionProps {
  data: InteriorBlockData;
}

const FindYourOwn: FC<FindYourOwnProps> = (props) => {
  const { data, ...restProps } = props;
  const { tabs, tabsList, title } = data;
  const { isDesktop } = useMedias();
  const { t } = useTranslation();

  const updatedTabs = useMemo(() => {
    return tabs.map((tab) => ({
      ...tab,
      image: {
        ...tab.image,
        description: {
          ...tab.image.description,
          text: t(`find-yours.${tab.id}.description`),
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const [selectedInterrior, setSelectedInterrior] = useState(updatedTabs[0]);
  const [animate, setAnimate] = useState(false);

  const updatedTabsList = useMemo(() => {
    return tabsList.map((list) => ({
      ...list,
      label: t(`tabs.${list.id}`),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsList]);

  const mainTitle = (
    <>
      <h2 className={styles.title}>{t('find-yours.title')}</h2>
      {title.icon && <Img className={styles.titleIcon} src={title.icon} />}
    </>
  );

  const filteredProductList = useMemo(() => {
    if (isDesktop) {
      return selectedInterrior.products;
    }
    return selectedInterrior.products.slice(0, 3);
  }, [selectedInterrior, isDesktop]);

  const handleChangeInterrior = useCallback(
    (_e, tab: Tab) => {
      const interior = updatedTabs.find((item) => item.id === tab.id);

      if (interior.id === selectedInterrior.id) return;

      setSelectedInterrior(interior);
      setAnimate((prev) => !prev);
    },
    [updatedTabs, selectedInterrior],
  );

  const selectedLabel = useMemo(() => {
    const selectedTab = tabsList.find((item) => item.id === selectedInterrior.id);
    return selectedTab?.label;
  }, [selectedInterrior, tabsList]);

  useEffect(() => {
    setSelectedInterrior(updatedTabs[0]);
  }, [updatedTabs]);

  return (
    <ProductsInInterior
      {...restProps}
      tabs={
        tabs.length > 1 && (
          <ButtonTabs
            scrollable
            className={styles.tabs}
            defaultValue={selectedInterrior.id}
            tabs={updatedTabsList}
            onChangeTab={handleChangeInterrior}
          />
        )
      }
      analyticsTitle={selectedLabel}
      img={selectedInterrior.image}
      products={filteredProductList}
      title={mainTitle}
      animate={animate}
    />
  );
};

export default FindYourOwn;
