import { memo } from 'react';
import { cn } from '@divlab/divanui';

import CrossSaleProductCard from '@Components/CrossSaleProductCard';
import CrossSaleSection from '@Components/CrossSaleSection';
import styles from './Hits.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProductsSectionData } from '@Pages/PageIndex/typings';

export interface HitsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  section: ProductsSectionData;
}

const Hits: FC<HitsProps> = (props) => {
  const { className, section, ...restProps } = props;

  return (
    <div {...restProps} className={cn(className, styles.wrapper)}>
      <CrossSaleSection
        className={styles.sectionCrossSale}
        cnGallery={styles.gallery}
        analyticsTitle={section.header}
        title={section.header}
        text={section.text}
        products={section.items}
        typeSection='main'
        renderItem={(productCardProps) => (
          <div className={styles.productItem}>
            <CrossSaleProductCard {...productCardProps} />
          </div>
        )}
      />
    </div>
  );
};

export default memo(Hits);
