import { memo, useCallback, useState } from 'react';
import { cn, NavArrows } from '@divlab/divanui';

import Phone from '@Components/Phone';
import Section from '@Components/Section';
import InstagramGallery from '@Components/InstagramGallery';
import styles from './InstagramSection.module.css';

import type { FC, HTMLAttributes, ReactChild } from 'react';
import type { ProgressOptions } from '@divlab/divanui';
import type { InstagramPostData, InstagramAccountData } from '@Types/InstagramPost';
import type { TypeSection } from '@Components/Section';

export interface InstagramSectionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string;
  title?: string;
  description?: ReactChild | ReactChild[];
  posts: InstagramPostData[];
  hasPromoPlaceholder?: boolean;
  account?: InstagramAccountData;
  footerText?: string;
  typeSection?: TypeSection;
}

const InstragramSection: FC<InstagramSectionProps> = (props) => {
  const {
    className,
    title,
    description,
    posts,
    hasPromoPlaceholder,
    account,
    footerText,
    typeSection,
    ...restProps
  } = props;
  const [track, setTrack] = useState<ProgressOptions>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const footerPhoneElement = /<a .*href="tel:.+>(.+)<\/a>/.exec(footerText);

  const normalizeSlide = useCallback(
    (value: number) => {
      if (value < 0) return 0;
      if (value > posts.length) return posts.length;

      return value;
    },
    [posts.length],
  );

  const handleChangeProgress = useCallback((opts: ProgressOptions) => {
    setTrack(opts);
  }, []);

  const handleChangeCurrent = useCallback(({ current }) => {
    setSlideIndex(current);
  }, []);

  const handlePrev = useCallback(() => {
    setSlideIndex((prev) => normalizeSlide(prev - 1));
  }, [normalizeSlide]);

  const handleNext = useCallback(() => {
    if (track && track.finished) return;

    setSlideIndex((prev) => normalizeSlide(prev + 1));
  }, [normalizeSlide, track]);

  return (
    <Section
      {...restProps}
      className={cn(styles.section, className)}
      title={title}
      description={description}
      typeSection={typeSection}
      arrows={
        track?.width < 100 && (
          <div className={styles.navArrows}>
            <NavArrows onPrev={handlePrev} onNext={handleNext} />
          </div>
        )
      }
    >
      <div className={styles.wrapperGallery}>
        <InstagramGallery
          className={styles.gallery}
          slideIndex={slideIndex}
          posts={posts}
          account={account}
          hasPromoPlaceholder={hasPromoPlaceholder}
          onChangeProgress={handleChangeProgress}
          onChangeCurrent={handleChangeCurrent}
        />
      </div>

      {footerPhoneElement && (
        <div className={styles.footerText}>
          <span dangerouslySetInnerHTML={{ __html: footerText.split(footerPhoneElement[0])[0] }} />
          <Phone className={styles.footerPhoneLink} phone={footerPhoneElement[1]} />
          <span dangerouslySetInnerHTML={{ __html: footerText.split(footerPhoneElement[0])[1] }} />
        </div>
      )}

      {footerText && !footerPhoneElement && (
        <div className={styles.footerText} dangerouslySetInnerHTML={{ __html: footerText }} />
      )}
    </Section>
  );
};

export default memo(InstragramSection);
