import InStock from '../InStock';

import type { FC } from 'react';
import type { InStockData } from '@Pages/PageIndex/typings';

export interface CategoriesInStockBlockProps {
  data: InStockData;
}

const CategoriesInStockBlock: FC<CategoriesInStockBlockProps> = (props) => {
  const { data } = props;
  if (Array.isArray(data) && !data.length) {
    return null;
  }

  return Array.isArray(data) ? (
    <InStock data={data} />
  ) : (
    data.B && <InStock data={data.B} mainLink={data.mainLink} />
  );
};

export default CategoriesInStockBlock;
