import { memo } from 'react';
import { cn, Discount, Price } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import useRenderType from '@Hooks/useRenderType';
import useTranslation from '@Queries/useTranslation';
import ProductLike from '@Components/ProductLike';
import Link from '@Navigation/Link';
import getProductName from '@Utils/getProductName';
import ProductImage from '@Components/ProductImage';
import styles from './HorizontalProductCard.module.css';

import type { HTMLAttributes, FC, MouseEvent } from 'react';
import type { ProductData } from '@Types/Product';

export interface HorizontalProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  onClickProductLink?: (e: MouseEvent) => void;
}

const HorizontalProductCard: FC<HorizontalProductCardProps> = (props) => {
  const { className, product, onClickProductLink, ...restProps } = props;
  const { isCSR } = useRenderType();
  const { t } = useTranslation();
  const { country } = useRequest();
  const hasExpired = product.price.expired > 0;
  const hasDiscount = product.price.discount > 0;
  const [firstImage] = product.images;
  const isUZB = country === 'UZB';

  return (
    <div
      {...restProps}
      className={cn(
        styles.card,
        {
          [styles.promo]: product.promo,
        },
        className,
      )}
    >
      <div className={styles.img}>
        <Link to={product.link} onClick={onClickProductLink}>
          <ProductImage
            className={styles.image}
            cnImage={styles.cnImage}
            src={firstImage?.src}
            alt={getProductName(product)}
          />
        </Link>

        <div className={styles.actions}>
          <ProductLike product={product} />
        </div>
      </div>
      <div>
        <Link className={styles.name} to={product.link} onClick={onClickProductLink}>
          {getProductName(product)}
        </Link>
        <div className={styles.price}>
          {/* Требование SEO чтобы от сервера всегда приходила цена,
            и только на клиенте изменялась на Нет в наличии */}
          {isCSR && !product.isActive ? (
            <div>{t('ui.not-available')}</div>
          ) : (
            <>
              <Price className={styles.actualPrice} price={product.price.actual} />
              &nbsp;
              {hasExpired && (
                <Price
                  expired
                  className={styles.expiredPrice}
                  price={product.price.expired}
                  withoutCurrency={isUZB}
                />
              )}
              {hasDiscount && (
                <Discount
                  className={cn(styles.discount, { [styles.uzbDiscount]: isUZB })}
                  value={product.price.discount}
                  view='grayStroke'
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(HorizontalProductCard);
