import { memo, useState, useCallback } from 'react';
import { cn, GalleryWithDots } from '@divlab/divanui';

import { useDevPanel } from '@Stores/DevPanel';
import NavSideArrows from '@Components/NavSideArrows';
import CardInView from '@Components/CardInView';
import AdvertisingMarker from '@Promo/elems/AdvertisingMarker';
import SmartRender from '@Components/SmartRender';
import DefaultTemplate from './templates/DefaultTemplate';
import FRKTemplate from './templates/FRK/FRKTemplate';
import SwitchThemeTemplate from './templates/SwitchThemeTemplate';
import ATRTemplate from './templates/ATR/ATRTemplate';
import TextWithLinksTemplate from './templates/TextWithLinksTemplate';
import YandexTemplate from './templates/Yandex/YandexTemplate';
import PromocodeTemplate from './templates/PromocodeTemplate';
import PromocodeNewTemplate from './templates/PromocodeNewTemplate';
import styles from './MainGallery.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { MainBannerData, Promotion } from '@Promo/typings';

export interface MainGalleryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  promotions: Promotion<MainBannerData>[];
}

const MainGallery: FC<MainGalleryProps> = (props) => {
  const { className, promotions, ...restProps } = props;
  const { currentDate } = useDevPanel();
  const [slideIndex, setSlideIndex] = useState(0);

  const handleChangeCurrent = useCallback(({ current }) => {
    setSlideIndex(current);
  }, []);

  return (
    <div
      {...restProps}
      className={cn(styles.galleryWrapper, { [styles.empty]: promotions.length < 1 }, className)}
    >
      {promotions.length > 0 && (
        <GalleryWithDots
          className={styles.gallery}
          slideIndex={slideIndex}
          onChangeCurrent={handleChangeCurrent}
          renderButtons={({ onPrev, onNext }) => {
            const banner = promotions[slideIndex].materials[0];

            if (!banner) return null;
            if (promotions.length < 2) return null;

            return (
              <div className={styles.arrowWrapper}>
                <NavSideArrows
                  view='chevron'
                  inversed={banner.inversed}
                  onPrev={onPrev}
                  onNext={onNext}
                  cnArrows={styles.arrows}
                />
              </div>
            );
          }}
        >
          {promotions.map((promotion, index) => {
            const banner = promotion.materials[0];

            return (
              <div
                key={promotion.name}
                className={styles.subwrapper}
                data-testid={`main-banner-slide${index + 1}`}
              >
                <CardInView
                  listTitle='main-slider'
                  position={index}
                  card={banner}
                  cardType='promo'
                  className={styles.bannerContainer}
                  isSingleCard
                  id={banner.id}
                  targetPathname={banner.link}
                >
                  {(() => {
                    switch (banner.template || 'default') {
                      case 'default':
                        return (
                          <DefaultTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'promocode':
                        return (
                          <PromocodeTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'promocodeNew':
                        return (
                          <PromocodeNewTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'FRK':
                        return (
                          <FRKTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'SwitchTheme':
                        return (
                          <SwitchThemeTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'ATR':
                        return (
                          <ATRTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'TextWithLinks':
                        return (
                          <TextWithLinksTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                      case 'Yandex':
                        return (
                          <YandexTemplate
                            key={banner.id}
                            promotion={promotion}
                            banner={banner}
                            currentDate={currentDate}
                            lazy={index !== 0}
                          />
                        );
                    }
                  })()}
                  <SmartRender countries={['RUS']}>
                    {banner.text?.advertiser && (
                      <AdvertisingMarker
                        className={styles.infoPopup}
                        advertiserName={banner.text.advertiser}
                      />
                    )}
                  </SmartRender>
                </CardInView>
              </div>
            );
          })}
        </GalleryWithDots>
      )}
    </div>
  );
};

export default memo(MainGallery);
