import { memo, useEffect } from 'react';
import { cn, GalleryWithProgress } from '@divlab/divanui';
import { useInView } from 'react-intersection-observer';

import CategoryCard from '@Components/CategoryCard';
import CardInView from '@Components/CardInView';
import { useDeps } from '@Contexts/DI';
import styles from './Categories.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryData } from '@Pages/PageIndex/typings';

export interface CategoriesProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  categories: CategoryData[];
}
const listTitle = 'undermain_slider';

const Categories: FC<CategoriesProps> = (props) => {
  const { className, categories, ...restProps } = props;
  const { ref, inView } = useInView();
  const { analytics } = useDeps();

  useEffect(() => {
    if (!inView) return;

    setTimeout(() => {
      analytics.dispatchEvent('categories.view', {
        listTitle,
        label: 'Категория блок',
      });
    });
  }, [inView, analytics]);

  return (
    <div {...restProps} className={cn(className, styles.wrapper)} ref={ref}>
      <GalleryWithProgress cnTrack={styles.progressBar} cnGallery={styles.gallery}>
        {categories.map((category, index) => (
          <div key={category.id} className={styles.categoryItem}>
            <CardInView
              listTitle={listTitle}
              card={category}
              cardType='category'
              id={category.id.toString()}
              position={index}
            >
              <CategoryCard category={category} />
            </CardInView>
          </div>
        ))}
      </GalleryWithProgress>
    </div>
  );
};

export default memo(Categories);
