import { useRef } from 'react';
import { Link, cn } from '@divlab/divanui';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Section from '@Components/Section';
import Img from '@UI/Img';
import useMedias from '@Hooks/useMedias';
import { useDeps } from '@Contexts/DI';
import ProductsGallery from './elements/ProductsGallery';
import useTranslation from '@Queries/useTranslation';
import VerticalProductsList from './elements/VerticalProductsList';
import styles from './ProductsInInterior.module.css';

import type { FC, Key } from 'react';
import type { ProductData } from '@Types/Product';
import type { SectionProps } from '@Components/Section';
import type { FindYourOwnImage } from '@Pages/PageProduct';

export interface ProductsInInteriorProps extends SectionProps {
  img?: FindYourOwnImage;
  products?: ProductData[];
  analyticsTitle?: string;
  title: string | JSX.Element;
  animate: boolean;
}

const ProductsInInterior: FC<ProductsInInteriorProps> = (props) => {
  const { className, title, img, products, animate, analyticsTitle, ...restProps } = props;
  const { link: imgLink, text: imgText } = img?.description;
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMedias();
  const { analytics } = useDeps();
  const nodeRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const interiorImgSrc = isMobile ? img.src_360 : img.src;
  const analyticsListTitle = `${analyticsTitle} ФРК главная`;

  const handleLinkClick = () => {
    analytics.dispatchEvent('catalog.open', {
      action: 'click',
      label: analyticsTitle,
      targetPathname: imgLink.src,
    });
  };

  return (
    <>
      <Section title={title} {...restProps} className={cn(styles.container, className)}>
        <SwitchTransition>
          <CSSTransition
            key={animate as unknown as Key}
            timeout={800}
            nodeRef={nodeRef}
            addEndListener={(done) => {
              nodeRef.current.addEventListener('transitionend', done, false);
            }}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
          >
            <div ref={nodeRef} className={cn(styles.wrapper, styles.opacityTransition)}>
              <div className={styles.imgContainer}>
                <Link to={imgLink.src} className={styles.descriptionLink} onClick={handleLinkClick}>
                  <Img className={styles.interiorImage} src={interiorImgSrc} alt={img.alt} />
                  {img.description && (
                    <div className={styles.imgDescription}>
                      <div className={styles.descriptionTitle}>{imgText}</div>
                      <Link underlined view='white' className={styles.descriptionLink}>
                        {t('find-yours.see-all')}
                      </Link>
                    </div>
                  )}
                </Link>
              </div>
              {!isDesktop && (
                <VerticalProductsList products={products} analyticsTitle={analyticsListTitle} />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Section>
      {isDesktop && (
        <SwitchTransition>
          <CSSTransition
            key={animate as unknown as Key}
            timeout={800}
            nodeRef={listRef}
            addEndListener={(done) => {
              listRef.current.addEventListener('transitionend', done, false);
            }}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
          >
            <div ref={listRef} className={styles.opacityTransition}>
              <ProductsGallery
                className={styles.gallery}
                analyticsTitle={analyticsListTitle}
                products={products}
              />
            </div>
          </CSSTransition>
        </SwitchTransition>
      )}
    </>
  );
};

export default ProductsInInterior;
