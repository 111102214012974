import { useState } from 'react';
import { Popup, cn, useMedias } from '@divlab/divanui';
import { IconInfoPopup } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import useTranslation from '@Queries/useTranslation';
import styles from './AdvertisingMarker.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface AdvertisingMarkerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  advertiserName: string;
}

const AdvertisingMarker: FC<AdvertisingMarkerProps> = (props) => {
  const { advertiserName, className, ...restProps } = props;
  const { isMobile } = useMedias();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const { t } = useTranslation();

  const needToShowPopup = visiblePopup && !isMobile;

  const handleMouseEnter = () => {
    setVisiblePopup(true);
  };

  const handleMouseLeave = () => {
    setVisiblePopup(false);
  };

  const handleClick = () => {
    if (!isMobile) return;

    ModalWindows.open('AdvertiserInfo', { advertiserName });
  };

  return (
    <div
      {...restProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      onClick={handleClick}
    >
      <div className={cn(styles.container, { [styles.hovered]: needToShowPopup })}>
        <div className={styles.text}>{t('ui.advertising')}</div>
        <IconInfoPopup />

        <Popup className={styles.popup} visible={needToShowPopup}>
          {`${t('ui.advertiser')} ${advertiserName}`}
        </Popup>
      </div>
    </div>
  );
};

export default AdvertisingMarker;
